export function updateLocalStorage(type: string, item: any) {
  // Mettre à jour l'objet avec les valeurs passées en paramètre
  localStorage.setItem(type, JSON.stringify(item));
}

export function loadLocalStorage(type: string): any {
  // Récupérer l'objet du local storage
  const objetLocal = JSON.parse(localStorage.getItem(type)??"{}");
  return objetLocal;
}

export function clearLocalStorage() {
  // Vider le local storage
  localStorage.setItem("tab", "roundTrip");
  localStorage.setItem(
    "option",
    JSON.stringify({
      directFlight: false,
      refundable: false,
      modifiable: false,
      lowest: false,
      strictSearch: false,
    })
  );
  localStorage.setItem("cabinType", JSON.stringify([]));
  localStorage.setItem("fareType", JSON.stringify([]));
  localStorage.setItem("oras", JSON.stringify([]));
  localStorage.setItem("flights", JSON.stringify([]));
  localStorage.setItem("paxs", JSON.stringify([]));
  localStorage.setItem("times", JSON.stringify([]));
  localStorage.setItem("inputPax", JSON.stringify([]));
  localStorage.setItem("program", JSON.stringify([]));
}

export function saveAllLocalStorage(
  tab: string,
  flights: any,
  paxs: any,
  cabinType: any,
  oras: any,
  fareType: any,
  option: any,
  program: any
) {
  localStorage.setItem("tab", tab);
  localStorage.setItem("flights", JSON.stringify(flights));
  localStorage.setItem("paxs", JSON.stringify(paxs));
  localStorage.setItem("cabinType", JSON.stringify(cabinType));
  localStorage.setItem("oras", JSON.stringify(oras));
  localStorage.setItem("fareType", JSON.stringify(fareType));
  localStorage.setItem("option", JSON.stringify(option));
  localStorage.setItem("program", JSON.stringify(program));
}
